// General
$primary-color: #283681;
$secondary-color: #283681;
$tertiary-color: #f7f8f9;
$accent-color: #485cc6;
$negative-color: #ffffff;

// Text
$text-primary: #283681;
$text-secondary: #ffffff;
$text-tertiary: #0a0c0f;
$text-default: #1f252e;
$text-disabled: #ffffff;
$text-placeholder: #7589a3;
$text-off: #5c6f8a;

$text-primary-hover: #003155;

// Colors
$color-white: #ffffff;
$color-positive: #05944f;
$color-warning: #ffc043;
$color-negative: #e11900;

// Background
$background-primary: #ffffff;
$background-active: #0073c6;
$background-error: #ff6060;
$background-success: #07b082;
$background-primary-soft: #aadbff;
$background-bar: #283681;
$background-option: #e3f3ff;

// Buttons
$background-button-primary-hover: #283681;
$background-button-primary-negative-hover: #ba1500;
$background-button-primary-disabled: #d1d8e0;
$background-button-secondary-hover: #eceef9;
$text-button-tertiary-disabled: #47576b;

// Borders
$border-active: #71c4ff;
$border-default: #d1d8e0;
$border-positive: #05944f;
$border-negative: #e11900;
$unit-accordion-border: #7b8fff;

// Chips
$chip-primary-background: #e4e7f7;
$chip-primary-color: #485cc6;

// Progress bar
$progress-bar-background: #96a1de;
