.viewer-sidebar-accordion {
  @extend .reset-accordion;

  h5 {
    &.accordion-title {
      @include paragraph-large(600, $accent-color);

      text-align: left;
    }
  }

  .card {
    background: transparent !important;
    border-radius: 0;
    padding: 1rem 0;
    margin-top: 0px !important;
    border: 1px solid $unit-accordion-border;
    border-left: none;
    border-right: none;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .card-header,
    .btn {
      background: transparent !important;
    }

    .card-body {
      padding: 0;
    }
  }

  .accordion-panel-content {
    margin-top: rem(32px);
    margin-left: rem(25px);

    .accordion-item {
      @include paragraph-small;

      margin-bottom: rem(16px);

      &:last-child {
        margin-bottom: 0;
      }

      &--active {
        font-weight: 700;
        color: $accent-color;
      }
    }
  }
}
