@import "variables";
@import "mixin/typography";

.toast-container.toast-bottom-center {
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
  padding: 0 1rem;

  .toast-info,
  .toast-error,
  .toast-success {
    width: fit-content;
    border-radius: rem(6px);
    padding: 0.5rem 1rem;

    .toast-message {
      @include input-text;
      line-height: 24px;
      color: $color-white;
      padding-left: rem(30px);
      margin-top: rem(2px);
    }
  }

  .toast-info {
    background-image: url("/assets/images/icons/toastr/info-icon.svg");
    background-color: #e4e7f7;
    border: none;

    .toast-message {
      color: #485cc6;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .toast-error {
    background-image: url("/assets/images/icons/toastr/error-icon.svg");
    background-color: $background-error;
  }

  .toast-success {
    background-image: url("/assets/images/icons/toastr/success-icon.svg");
    background-color: #07b082;
  }
}
