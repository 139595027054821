.unit-student-content {
  @extend .reset-accordion;

  .card {
    .card-header {
      button {
        border: 0;
        background-color: transparent;
      }
    }
  }
}
