@import "variables";
@import "functions";

@mixin display-large($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(56px);
  line-height: rem(64px);
  font-weight: $weight;
  color: $text-primary;
}

@mixin display-medium($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(48px);
  line-height: rem(56px);
  font-weight: $weight;
  color: $text-primary;
}

@mixin display-small($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(40px);
  line-height: rem(48px);
  font-weight: $weight;
  color: $text-primary;
}

@mixin h1-text($weight: 500, $size: 24px, $color: $text-primary) {
  font-family: $tt-hoves;
  font-size: rem($size);
  line-height: rem(32px);
  font-weight: $weight;
  color: $color;
}

@mixin h2-text($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(32px);
  line-height: rem(40px);
  font-weight: $weight;
  color: $text-primary;
}

@mixin h3-text($weight: 400, $color: $secondary-color) {
  font-family: $tt-hoves;
  font-size: rem(28px);
  line-height: rem(32px);
  font-weight: $weight;
  color: $color;
}

@mixin h4-text($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(24px);
  line-height: rem(32px);
  font-weight: $weight;
  color: $secondary-color;
}

@mixin paragraph-large($weight: 400, $color: $text-tertiary) {
  //uguale ad h5-text
  font-family: $tt-hoves;
  font-size: rem(18px);
  line-height: rem(24px);
  font-weight: $weight;
  color: $color;
}

@mixin paragraph-medium($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(16px);
  line-height: rem(24px);
  font-weight: $weight;
  color: $text-tertiary;
}

@mixin paragraph-small($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(14px);
  line-height: rem(20px);
  font-weight: $weight;
  color: $text-tertiary;
}

@mixin input-text($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(15px);
  line-height: rem(20px);
  font-weight: $weight;
  color: $text-tertiary;
}

@mixin label-field($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(14px);
  line-height: rem(16px);
  font-weight: $weight;
  color: $text-tertiary;
}

@mixin tab($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(12px);
  line-height: rem(15px);
  font-weight: $weight;
  color: $text-tertiary;
}

@mixin chip($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(10px);
  line-height: rem(17px);
  font-weight: $weight;
  color: $chip-primary-color;
  text-transform: uppercase;
}

@mixin text-xs($weight: 400) {
  font-family: $tt-hoves;
  font-size: rem(12px);
  line-height: rem(15px);
  font-weight: $weight;
}
