/* Fonts Declaration */

@font-face {
  font-family: 'TTHoves';
  src: url('/assets/fonts/TTHoves-Regular.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTHoves';
  src: url('/assets/fonts/TTHoves-Medium.woff');
  font-weight: 500;
  font-style: normal;
}
