/* Importing Normalize.css */
@import "~normalize.css";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Toastr SCSS file. */
@import "~ngx-toastr/toastr";
@import "~ngx-toastr/toastr-bs4-alert";
@import "toastr.scss";

/* Importing fonts */
@import "fonts";

/* Importing buttons */
@import "buttons";

/* Importing custom checkbox */
@import "checkbox";

/* Importing Owl Carousel */
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "owl-carousel";

/* Importing Videogular Style */
@import "~@videogular/ngx-videogular/fonts/videogular.css";

/* Importing International tel input custom style */
@import "international-tel-input";

/* Importing ngb-typeahead style */
@import "ngb-typeahead";

/* Importing accordion style */
@import "accordion/reset-accordion";
@import "accordion/_accordion-top-bar";
@import "accordion/accordion-create-unit-lesson";
@import "accordion/create-test-content-accordion";
@import "accordion/accordion-viewer-sidebar";
@import "accordion/accordion-unit-student-content";
@import "tables/material_table";

@import "top-bar";
@import "global";
@import "variables";
