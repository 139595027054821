.control {
  display: block;
  position: relative;
  padding-left: 1.4rem;
  margin-bottom: .6rem;
  padding-top: .05rem;
  cursor: pointer;

  font-family: 'TTFirsNeue', sans-serif;
  font-size: .85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #fff;
  border: 2px solid #111112;
  border-radius: 4px;
}

.control input:checked~.control_indicator {
  background: #111112;
}

.control input:disabled~.control_indicator {
  background: #111112;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked~.control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 3px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled~.control_indicator:after {
  border-color: #7b7b7b;
}
