@import "mixin";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: $tertiary-color;
}

* {
  box-sizing: border-box;
}

.background-dark {
  width: 100%;
  background-color: #111112;
  background-image: url("/assets/images/backgrounds/background-login.svg");
  background-size: cover;
}

@media (max-width: 768px) {
  .background-dark {
    background-image: url("/assets/images/backgrounds/background-login-mobile.svg");
  }
}

.position-relative {
  position: relative !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.skeleton-loader-item {
  span {
    &:focus {
      outline: none !important;
    }
  }
}

.overlay-play-container.vg-icon-play_arrow {
  background: black;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vg-overlay-play:hover {
  opacity: 0.8 !important;
}

.overlay-play-container.vg-icon-play_arrow {
  font-size: 40px !important;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: initial !important;
}

.editor {
  min-height: 500px !important;
}

.click-disabled {
  pointer-events: none;
}

.no-modal-backdrop {
  opacity: 0 !important;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: $tertiary-color;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: $color-negative;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: $color-warning;
}

.mat-progress-bar-fill::after {
  background-color: $color-positive;
}

.progress-bar {
  background-color: $progress-bar-background !important;
}

.content-container {
  width: rem($desktop-container-width);
  margin: 0 auto;

  @include breakpoint(tablet) {
    width: rem($tablet-container-width);
  }

  @include breakpoint(mobile) {
    width: 100%;
    padding: 0 1rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

blockquote {
  p {
    opacity: 0.7;
    font-style: italic;

    &::before,
    &::after {
      content: '"';
    }
  }
}
