@import "variables";

#ngb-typeahead-0,
#ngb-typeahead-1 {
  width: rem(247px);

  @media (max-width: 991px) {
    width: $tablet-container-width !important;
  }

  @media (max-width: 672px) {
    width: $mobile-container-width !important;
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select-staff-typeahead {
  & + .dropdown-menu {
    border-radius: 0 !important;
    width: calc(100% - 30px) !important;
    max-height: 300px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $dark-gray;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $dark-color;
    }
  }
}
