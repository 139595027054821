@import "mixin";

.mat-table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.mat-table {
  width: 100%;
  table-layout: auto;
}

.mat-header-cell {
  span {
    @include tab(500);

    color: #000000;
    margin-bottom: 0;
  }
}

.mat-header-row {
  border: none;
}

.mat-row {
  border: none;
  background: #ffffff;
  border-radius: 0.75rem;
  margin: rem(8px) 0;

  p {
    @include tab();

    line-height: 14px;
    color: #000000;
    margin-bottom: 0;
  }

  .truncate {
    width: calc(100%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link {
    @include tab();
    line-height: 14px;
    text-decoration-line: underline;
    color: #485cc6;
    cursor: pointer;
  }

  .details {
    font-weight: 500;
    line-height: 24px;
    color: $text-primary;
    cursor: pointer;
  }
}

.mat-header-cell,
.mat-cell {
  padding: 0 8px;
}

.mat-cell {
  background: #fff;

  &:first-child {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  &:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
}

/* Custom Elements */
.details-action-link {
  @include tab(500);

  line-height: 24px;
  color: $text-primary;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
