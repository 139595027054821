@import "mixin/typography";
@import "mixin/application";
@import "mixin/table";

@mixin loading-span {
  span {
    animation-name: opacity;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 1.3s;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: opacity;
    -moz-animation-duration: 1.3s;
    -moz-animation-iteration-count: infinite;

    &:not(:last-child) {
      margin-right: rem(3px);
    }

    &:nth-child(2) {
      animation-delay: 100ms;
      -webkit-animation-delay: 100ms;
      -moz-animation-delay: 100ms;
    }

    &:nth-child(3) {
      animation-delay: 300ms;
      -webkit-animation-delay: 300ms;
      -moz-animation-delay: 300ms;
    }
  }
}

@mixin nav-tabs {
  .nav-tabs {
    display: flex;
    border-bottom: rem(0.2px) solid $border-default;

    @include breakpoint(tablet) {
      justify-content: space-between;
    }

    .nav-link {
      @include tab;

      letter-spacing: 0.06em;
      text-transform: uppercase;
      position: relative;
      border: none;
      padding: 0.7rem;
      background-color: transparent;

      &.active {
        font-weight: 600;
        border: none;

        &:hover {
          border: none;
          color: inherit;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: $accent-color;
          height: rem(3px);
          border-radius: 1rem;
        }
      }

      &:focus,
      &:hover {
        border: none;
        color: $text-primary-hover;
      }
    }

    .nav-item {
      margin-bottom: rem(-2px);
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@mixin breakpoint($point) {
  @if $point==tablet {
    @media only screen and (max-width: $desktop-container-width) {
      @content;
    }
  } @else if $point==mobile {
    @media only screen and (max-width: $tablet-container-width) {
      @content;
    }
  }
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    border-radius: 2px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #b3bdcc;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $accent-color;
  }
}
