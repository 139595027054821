@import "mixin";

.owl-carousel {
  .owl-item {
    cursor: pointer;
  }

  .owl-item img {
    display: inline-block;
    width: rem(22px);
    height: rem(22px);
  }
}

.owl-theme {
  .owl-nav {
    padding: 0;
    background: none;
    position: absolute;
    top: 27%;
    left: 0;
    right: 0;

    .owl-prev,
    .owl-next {
      position: absolute;
      background: none;

      &.disabled {
        pointer-events: none;
      }

      img {
        width: rem(54px);
        height: rem(54px);
      }

      &:hover {
        background: none;
      }
    }

    .owl-prev {
      left: -63px;
      transform: rotate(180deg);
    }

    .owl-next {
      right: -44px;
    }
  }
}
