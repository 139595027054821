@import "functions";
@import "colors";

// Fonts
$tt-hoves: "TTHoves", sans-serif;

// Colors
$dark-color: #111112;
$dark-gray: #3b3b3b;
$light-gray: #f8f8f8;

// Authentication
$logo-height: rem(56px);

// Application
$application-sidebar-width: rem(276px);
$application-top-bar-height: 4rem;

// Breakpoint
$desktop-container-width: 1095px;
$tablet-container-width: 672px;
$mobile-container-width: 330px;
