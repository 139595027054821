@import "./mixin/typography";
@import "functions";

.iti {
  width: 100% !important;
  height: rem(52px);

  .iti__selected-flag {
    width: 100% !important;
    height: 100%;
  }

  input {
    @include input-text;
    width: 100%;
    height: rem(52px);
    border-radius: rem(6px);
    border: solid rem(1.2px) $border-default;

    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
      border: solid rem(1.4px) $secondary-color;
    }

    &.invalid {
      border: rem(1.6px) solid $color-negative;
    }

    &[type="tel"] {
      padding-left: rem(85px) !important;
    }
  }

  .iti__flag-container .dropdown-menu.country-dropdown {
    display: none;
  }

  .iti__flag-container.open .dropdown-menu.country-dropdown {
    display: block;
    width: rem(247px);
    inset: 100% auto auto 0;
    transform: translateY(0px);

    position: absolute;
    z-index: 1000;

    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;

    @media (max-width: 991px) {
      width: $tablet-container-width !important;
    }

    @media (max-width: 672px) {
      width: $mobile-container-width !important;
    }
  }

  .tooltip {
    display: block;
    pointer-events: none;
    position: absolute;
    z-index: 1070;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;

    & .arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem;

      &:before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
    }
    &.show {
      opacity: 0.9;
    }
  }

  .bs-tooltip-top {
    padding: 0.4rem 0;

    &.tooltip .arrow:before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000;
    }

    & .arrow {
      bottom: 0;
    }
  }

  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }

  #country-search-box {
    width: 100%;
  }
}
