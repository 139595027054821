.reset-accordion {
  .card {
    background-color: $background-primary;
    border: none;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    .card-body {
      padding: 0.5rem 0;
    }

    .card-header {
      border-bottom: 0;
    }

    .card-header,
    .btn-link {
      padding: 0;
      background-color: $background-primary;
    }

    .btn-link {
      width: 100%;

      &:focus,
      &:hover {
        text-decoration: none;
        box-shadow: none;
      }
    }
  }
}
