@import "variables";

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  background-color: #111112;
  border-color: #111112;
  border-radius: 0.2rem;
  transition: all 0.2s ease-in;
  padding: 0.75rem 2.38rem;

  font-family: TTFirsNeue, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  &:hover {
    background-color: #111112;
    border-color: #111112;
    opacity: 0.9;
  }

  &:focus {
    background-color: #111112 !important;
    border-color: #111112 !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #111112;
    border-color: #111112;
    opacity: 0.6;
  }
}

.btn-outline {
  @extend .btn-primary;
  background: transparent;
  color: #111112;

  &:hover {
    color: #fff;
  }

  &:focus {
    background: transparent !important;
    color: #111112;
  }
}

.btn-primary-gradient {
  border-radius: 0.3rem;
  background-image: linear-gradient(193deg, rgba(54, 54, 54, 1) 26%, rgba(17, 17, 18, 1) 75%);
  border: none;
  transition: all 0.1s ease-in;
  padding: 0.9rem 3.18rem;

  font-family: TTFirsNeue, sans-serif;
  font-size: 1.3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #b2b2b2;

  &:hover {
    color: #b2b2b2;
    opacity: 0.9;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &.active {
    color: #fff !important;
    border: 2px solid #ffffff !important;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
}

.btn-secondary {
  background-color: $light-gray;
  border-color: $light-gray;
  border-radius: 0.2rem;
  transition: all 0.2s ease-in;
  padding: 0.75rem 2.38rem;

  font-family: TTFirsNeue, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #101012;

  &:hover {
    background-color: #efefef;
    border-color: #efefef;
    color: #101012;
  }

  &:focus {
    background-color: $light-gray !important;
    border-color: $light-gray !important;
    box-shadow: none !important;
    color: #101012 !important;
    outline: none !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $light-gray;
    border-color: $light-gray;
    opacity: 0.6;
  }
}

.btn-large {
  width: 180px;
  height: 44px;
  color: $dark-color !important;
}

.btn-large-white {
  width: 180px;
  height: 44px;
  color: #ffffff !important;
}

.btn-extra-large {
  width: 296px !important;
  height: 50px;
  display: block;

  @media (max-width: 560px) {
    width: 100%;
  }
}

.blue-button {
  background-color: #0bbff5;
  border-color: #0bbff5;
  color: #ffffff;

  &:hover {
    background-color: #00bbe8 !important;
    border-color: #00bbe8 !important;
  }

  &:focus {
    background-color: #0bbff5 !important;
    border-color: #0bbff5 !important;
  }

  &:disabled {
    background-color: $dark-color !important;
    border-color: $dark-color !important;
    opacity: 0.6;
  }
}

.button-create {
  font-family: $tt-hoves;
  font-weight: 400;
  font-size: rem(15px);
  background-color: $primary-color;
  width: rem(170px);
  height: rem(40px);
  border-radius: rem(6px);
  border: none;
  color: $color-white;
}

.button-show-more {
  width: 100%;
  height: rem(100px);
  display: flex;
  justify-content: center;
}
