.create-test-content-accordion {
  .card {
    margin-bottom: rem(12px);
    border-radius: rem(6px);
    background-color: #ffffff;
    border-color: transparent;

    .card-header {
      background-color: #fff;
      border-bottom: none;

      button {
        background-color: transparent;
      }
    }
  }
}
