@import "mixin/typography";

.application-top-bar {
  .card {
    border: none;

    a {
      @include paragraph-large;
      cursor: pointer;
    }

    .cursor-not-allowed {
      cursor: not-allowed !important;
    }
  }

  .card-header {
    padding: 0;
    border: none;
  }

  .card-body {
    padding: 1rem 0 0 3rem;
    background: $tertiary-color;
  }

  .btn {
    padding: 0;
  }
}

.top-bar-sub-links {
  @extend .application-top-bar;

  .card,
  .card-header,
  .card-body {
    background-color: transparent;
  }
}
