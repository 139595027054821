.create-unit-lesson-accordion {
  @extend .reset-accordion;

  .accordion-arrow {
    margin-right: rem(14px);
    margin-top: -4px;

    &-rotated {
      transform: rotate(90deg);
    }
  }

  h5 {
    &.accordion-title {
      @include paragraph-medium;

      text-align: left;
      padding-right: rem(4px);

      &.accordion-title-selected {
        font-weight: bold;
      }
    }
  }

  &.border-top {
    .card {
      border-top: 0.4px solid $text-placeholder;
    }
  }

  .card {
    background: transparent !important;
    border-bottom: 0.4px solid $text-placeholder;
    border-radius: 0;
    padding: 1rem 0;

    .card-header,
    .btn {
      background: transparent !important;
    }

    .card-body {
      padding: 0;
    }
  }

  .accordion-panel-content {
    margin-top: rem(24px);

    .accordion-item {
      @include paragraph-small;

      margin-bottom: rem(16px);

      &--active {
        padding: rem(5px) 0 rem(5px) rem(10px);
        background: #efefef;
        border-radius: rem(4px);
      }
    }
  }
}
